import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Svg from "react-inlinesvg"
import Slider from "react-slick"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/atoms/Button"
import Heading from "../components/atoms/Heading"
import SubHeader from "../components/atoms/SubHeader"
import TechnologyItem from "../components/atoms/TechnologyItem"
import ServicesWorkBox from "../components/molecules/ServicesWorkBox"
import DiagonalArrowIcon from "../images/icons/diagonal-arrow.svg"
import ServiceQuoteSection from "../components/organisms/ServiceQuoteSection"
import AuthorisedVendor from "../components/organisms/AuthorisedVendor"
import Brands from "../components/organisms/Brands"
import Testimonials from "../components/organisms/Testimonials"

export const query = graphql`
  query ($slug: String!) {
    servicePagesJson(slug: { eq: $slug }) {
      title
      metaTitle
      metaDescription
      slug
      pageSubHeader
      pageIntro
      pageHeader
      pageIcon {
        publicURL
      }
      sections {
        lead
        subtitle
        title
        technologies {
          name
          icon {
            publicURL
          }
          url
        }
        scopes {
          name
        }
        icon {
          publicURL
        }
      }
      works {
        title
        lead
        url
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 720, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
      quote {
        text
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1000, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
      featuredImage {
        absolutePath
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  }
`

const Service = ({ data }) => {
  const service = data.servicePagesJson

  const carouselSettings = {
    arrows: false,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    draggable: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "12%",
        },
      },
    ],
  }
  return (
    <Layout
      headerBg="bg-dark"
      logoColor="text-beige"
      headerLinksColor="sm:text-beige"
    >
      <Seo
        title={service.metaTitle}
        description={service.metaDescription}
        metaFeaturedImage={
          service.featuredImage.childImageSharp.gatsbyImageData
        }
      />
      <section className="py-8 md:py-20 md:pt-8 bg-dark">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="flex flex-col items-center">
            <Svg
              src={`${service.pageIcon.publicURL}`}
              width="80px"
              height="70px"
              className="text-primary stroke-current"
              title={service.pageSubHeader}
            />
            <h1 className="text-2xl text-beige font-extrabold mt-8 mb-12">
              {service.pageSubHeader}
            </h1>
            <div className="max-w-2xl mx-auto">
              <Heading
                variant="h2"
                component="h1"
                classes="text-beige text-center tracking-wide leading-9"
              >
                {service.pageHeader}
              </Heading>
            </div>
            <div className="text-beige text-base text-center mt-6 max-w-2xl mx-auto lg:px-8">
              {service.pageIntro}
            </div>
            <div className="mt-14 mb-8  lg:mb-0">
              <Button
                variant="contained"
                size="small"
                fullWidthMobile
                href="/contact"
              >
                Let's talk
              </Button>
            </div>
          </div>
        </div>
      </section>

      {service.sections.map((section, index) => {
        return (
          <section
            key={section.title}
            className={`py-8 md:py-20 ${
              index % 2 === 0 ? "bg-white" : "bg-beige"
            }`}
          >
            <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
              <div className="flex flex-col md:flex-row">
                <div className="flex-shrink-0 mb-6">
                  <div
                    className={`w-14 h-14 rounded-full flex items-center justify-center text-dark md:transform md:-translate-y-5 ${
                      index % 2 === 0 ? "bg-beige" : "bg-white"
                    }`}
                  >
                    <Svg
                      src={`${section.icon.publicURL}`}
                      width="32"
                      height="32"
                      className="text-dark stroke-current"
                      title={section.subtitle}
                    />
                  </div>
                </div>
                <div className="flex-grow md:pl-12">
                  <Heading
                    variant="h2"
                    component="h6"
                    classes="inline-block text-base font-extrabold"
                  >
                    {section.subtitle}
                  </Heading>
                  <div className="mt-6">
                    <Heading
                      variant="h3"
                      component="h2"
                      classes="md:whitespace-pre-line"
                    >
                      {section.title}
                    </Heading>
                  </div>
                  <div className="text-beige-160 mt-10 max-w-2xl whitespace-pre-line">
                    {section.lead}
                  </div>
                  {section.technologies && (
                    <div className="flex flex-wrap mt-6 md:mt-10">
                      {section.technologies.map(technology => {
                        return (
                          <TechnologyItem
                            key={technology.name}
                            label={technology.name}
                            url={technology.url}
                            icon={
                              <Svg
                                src={`${technology.icon.publicURL}`}
                                width="48"
                                height="48"
                                title={technology.name}
                              />
                            }
                          />
                        )
                      })}
                    </div>
                  )}
                  <div className="flex flex-wrap -mx-4 mt-14 md:mt-20">
                    {section.scopes.map(scope => {
                      return (
                        <div
                          key={scope.name}
                          className="w-full sm:w-1/3 md:w-1/4 px-4"
                        >
                          <div className="flex mt-6">
                            <div className="flex-shrink-0">
                              <DiagonalArrowIcon
                                className="stroke-primary mr-2"
                                width="16px"
                                height="16px"
                                title="Diagonal Arrow Icon"
                              />
                            </div>
                            <div className="text-base md:text-[20px] font-semibold md:whitespace-pre-line">
                              {scope.name}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      })}
      <section
        className={`${
          service.sections.length % 2 === 0 ? "bg-white" : "bg-beige"
        } py-8`}
      >
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="pt-10">
            <SubHeader label="Works" />
            <Heading variant="h2">
              Tread along our most recently fashioned paths
            </Heading>
            <div className="-mx-3 md:mx-0 mt-12">
              <div className="w-full works-carousel">
                <Slider {...carouselSettings}>
                  {service.works.map((work, index) => {
                    return (
                      <div key={index} className="md:w-1/3 px-3">
                        <ServicesWorkBox
                          title={work.title}
                          lead={work.lead}
                          url={work.url}
                        >
                          <GatsbyImage
                            image={getImage(work.image)}
                            quality={100}
                            alt={`${work.title} application created by Peak11`}
                          />
                        </ServicesWorkBox>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials
        header="Your journey with Peak11"
        subHeader="Testimonials"
      />
      <Brands />
      <AuthorisedVendor
        header="We are listed on the top review sites"
        subHeader="Verified company"
      ></AuthorisedVendor>
      <ServiceQuoteSection
        text={service.quote.text}
        image={getImage(service.quote.image)}
      />
    </Layout>
  )
}

export default Service
